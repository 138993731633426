import axios from 'axios'
import { Loading, Message } from 'element-ui'

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 10000
})

let loadingInstance
axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
// 请求拦截器
service.interceptors.request.use(function(config) {
  // 在发送请求之前做些什么
  if (loadingInstance) {
    loadingInstance.close()
  }
  loadingInstance = Loading.service({ background: 'rgba(255,255,255,1)' })

  return config
}, function(error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use((response) => {
  //在这里你可以判断后台返回数据携带的请求码
  if (response.status === 200) {
    loadingInstance.close()
    return response.data
  } else {
    Message.error({
      message: '服务异常，请稍后重试',
      onClose: () => {
        loadingInstance.close()
      }
    })
    // 非200请求报错
    throw Error(response.data.msg || '服务异常')
  }
}, function(error) {
  Message.error({
    message: '请求失败，请稍后重试',
    onClose: () => {
      loadingInstance.close()
    }
  })
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default service
