import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

//当前页面位置
import tags from './modules/tags'
import menu from './modules/menu'
import config from './modules/config'
// import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        tags,
        menu,
        config
    },
    plugins: [createPersistedState({
        key: 'my-app',
        storage: window.localStorage,
        paths:['tags','config']
    })]
    // getters
})

export default store
