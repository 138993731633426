<template>
  <div>
    <el-container>
      <el-header class="header">
        <NavBar/>
      </el-header>
      <el-main class="main">
        <router-view></router-view>
      </el-main>
      <el-footer class="footer">
        <NavFooter/>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar/NavBar.vue'
import NavFooter from '@/components/NavFooter/index.vue'

export default {
  name: 'App',
  components: { NavBar, NavFooter },
}
</script>

<style>
.header {
  height: auto !important;
  padding: 0 !important;
}

.main, .footer {
  padding: 0 !important;
  margin: 0;
}
.main{
  min-height: 50vh !important;
}
.footer{
  height: auto !important;
}

</style>
