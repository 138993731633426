import request from '@/utils/request'

// 获取路由
export const getRouters = (query) => {
  return request({
    url: '/webapi/menuWebsite/list',
    method: 'get',
    params: query
  })
}
