import Vue from 'vue'

const state = {
  // 是否展示页面位置信息
  showTags: false,
  // 页面位置值
  tagsVies: [{
    i: 0,
    tags: '首页',
    path: '/index'
  }]
}

const mutations = {
  // 页面位置数据
  ADD_TAGS_VIEW: (state, tagsView) => {
    if (tagsView) {
      tagsView.forEach(item => {
        Vue.set(state.tagsVies, item.i, item)
      })
    }
  },
  //修改showTags的值
  CHANGE_SHOW_TAGS: (state, flag) => {
    state.showTags = flag
  }
}

export default {
  namespaced: true,
  state,
  mutations
}

