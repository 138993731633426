import Vue from 'vue'
import App from './App.vue'

import './plugins/element.js'
import store from "./store"
import router from "./router";
import '@/assets/styles/yifa.css' // 自定义css
import './permission'

import { parseTime } from "@/utils/yifa";
// 全局方法挂载
Vue.prototype.parseTime = parseTime

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
