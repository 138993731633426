import request from '@/utils/request'

// 获取字典数据
export const getDict = (dictType) => {
  return request({
    url: '/webapi/dict/getDictByType/'+dictType,
    method: 'get',
  })
}

// 获取网站配置信息
export const getConfig = () => {
  return request({
    url: '/webapi/webconfig/list',
    method: 'get',
  })
}

// 获取轮播图数据
export const getSlidesList = () => {
  return request({
    url: '/webapi/slideshow/getSlideshowList',
    method: 'get',
  })
}
