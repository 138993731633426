import router from './router'
import store from './store'
// import { Loading } from 'element-ui';

router.beforeEach((to, from, next) => {
  // let loadingInstance = Loading.service({ fullscreen: true,background:'rgba(255, 255, 255, 1)' });
  // 判断是否已经加载路由以及配置信息
  let flagRouters = store.state.menu.topbarRouters.length > 0
  let flagConfig = store.state.config.configList.length > 0
  if (flagRouters && flagConfig) {
    next()
  } else {
    store.dispatch('menu/GenerateRoutes').then(topMenu => {
      router.addRoutes(topMenu) // 动态添加可访问路由表
      next({ ...to, replace: true }) // hack方法 确保addRoutes已完成
      store.dispatch('config/GenerateConfig').then(() => {
        console.log('济南医发医学检验实验室')
        // console.log(loadingInstance)
      })
    })
  }
})
