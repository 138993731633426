<template>
  <div class="footer-contian">
    <div class="footer-menu hidden-md-and-down">
      <el-row type="flex" justify="center">
        <el-col :sm="24" :md="20">
          <div class="m-flex-between-top">
            <img src="../../assets/images/bot-logo.png" class="bot-log">
            <div class="white size16 m-flex-between-center">
              <span class="contact tell rel">{{ configData.tell }}</span>
              <span class="contact email rel">{{ configData.email }}</span>
            </div>
          </div>

          <div class="bot-menu-top">
            <el-row>
              <el-col :md="6" :lg="10">
                <div class="size20 white bot-tips">健康<br>创造美好未来 </div>
              </el-col>
              <el-col :md="18" :lg="14">
                <el-row type="flex" justify="space-between">
                  <el-col :span="index===0 || index===1?5:4" v-for="(item,index) in topbarRouters" :key="index">
                    <div class="size14 white">{{ item.menuName }}</div>
                    <ul class="size14 white bot-menu-list">
                      <li class="menu" v-for="menu in menuTree[item.menuId].children" :key="menu.menuId"
                          @click="routerLink(item,menu)"
                      >{{ menu.menuName }}
                      </li>
                    </ul>
                  </el-col>
                </el-row>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="copyright">
      <el-row type="flex" justify="center">
        <el-col :sm="24" :md="20">
          <span class="white size12">{{ configData.copyright_value }} ｜<a href="https://beian.miit.gov.cn/"
                                                                          target="_blank" class="filings-link"
          > {{ configData.icp_record_number }}</a> </span>
          <span class="white size12">
            <img src="../../assets/images/security.png" class="security-img">
            <a href="https://beian.mps.gov.cn/#/query/webSearch?code=37010402441365"
               target="_blank" class="filings-link"
            > {{ configData.security }}</a>

          </span>
        </el-col>
      </el-row>
    </div>
  </div>

</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'NavFooter',
  computed: {
    ...mapState('config', ['configData']),
    ...mapState('menu', ['topbarRouters', 'menuTree'])
  },
  methods: {
    routerLink(item, menu) {
      this.$router.push({
        path: item.path,
        query: { menuId: item.menuId, activeId: menu.menuId, position: true }
      }).catch(() => {
      })
    }
  }
}
</script>

<style scoped>
.footer-menu {
  background: #172540;
  padding: 30px 0;
}

.copyright {
  height: 52px;
  line-height: 52px;
  background: #0F1E37;
  border-radius: 0px 0px 0px 0px;
  background: #0F1E37;
  text-align: center;
}

.bot-tips {
  opacity: .75;
  line-height: 36px;
  margin-left: 5px;
}

.bot-menu-top {
  margin-top: 28px;
}

.bot-log {
  width: 360px;
}

.contact {
  display: block;
  padding-left: 62px;
}

.contact:before {
  content: "";
  display: block;
  width: 42px;
  height: 42px;
  position: absolute;
  left: 0;
  top: 50%;
  margin-top: -21px;
}

.tell:before {
  background: url("@/assets/images/photo.png") center center no-repeat;
  background-size: 100%;
}

.email:before {
  background: url("@/assets/images/email.png") center center no-repeat;
  background-size: 100%;
}

.tell {
  margin-right: 45px;
}

.bot-menu-list li {
  opacity: .6;
  margin-top: 8px;
}

.filings-link {
  color: #fff;
  text-decoration: none;
}

.filings-link:hover {
  color: #0078E0;
}

.menu {
  cursor: pointer;
}
.security-img{
  width: 16px;
  margin-left: 15px;
  margin-right: 1px;
}
@media screen and (max-width: 992px) {
  .copyright{
    padding: 5px 0;
    height: auto;
    line-height: 24px;
  }
}
</style>
