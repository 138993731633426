import { getConfig } from '@/api/common'

const state = {
  // 网站配置信息
  configData: {},
  // 网站配置信息列表
  configList: []
}

const mutations = {
  // 页面位置数据
  SET_CONFIG_DATA: (state, data) => {
    state.configData = data
  },
  SET_CONFIG_LIST: (state, data) => {
    state.configList = data
  }
}
const actions = {
  // 生成路由
  GenerateConfig({ commit }) {
    return new Promise(resolve => {
      // 向后端请求路由数据
      getConfig({ pageNum: 1, pageSize: 100000 }).then(res => {
        // 顶部菜单
        const configData = filterConfig(res.rows)
        commit('SET_CONFIG_DATA', configData)
        commit('SET_CONFIG_LIST', res.rows)
        resolve(res.rows)
      })
    })
  }
}

// 信息改为对象形式
function filterConfig(list) {
  let config = {}
  list.forEach(item => {
    config[item.configKey] = item.configValue
  })
  return config
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

