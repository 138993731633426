// 处理左侧菜单
export function renderMenu(menuItems, vm) {
//   1、menuItems:树形菜单，vm:vue实例
//   2、组件以contentType的值命名
  menuItems.forEach(menuItem => {
    if (menuItem.children) {
      renderMenu(menuItem.children, vm) // 递归渲染子菜单
    } else {
      if (menuItem.menuType === 'C' && menuItem.routeName) {
        //   菜单类型，按path引入组件
        vm.$options.components[menuItem.routeName] = () => import(`@/views/${menuItem.path}`)
      } else if (menuItem.contentType && menuItem.menuType === 'F') {
        //   按钮类型，按组件以contentType的值引入组件
        let comName = menuItem.contentType.slice(0, 1).toUpperCase() + menuItem.contentType.slice(1).toLowerCase()
        vm.$options.components[comName] = () => import(`@/components/${comName}`)
      }
    }
  })
}

export function getFirstMenu(menuItems, vm) {
  menuItems.forEach(item => {
    if (item.children) {
      getFirstMenu(item.children, vm)
    } else {
      item.isExpand = true
      if (!vm.activeTitle) {
        vm.activeTitle = item.menuName
        vm.menuId = item.menuId
        if (item.menuType === 'C' && item.routeName) {
          //   菜单类型
          vm.currentCom = item.routeName
        } else if (item.contentType && item.menuType === 'F') {
          //   按钮类型
          let comName = item.contentType.slice(0, 1).toUpperCase() + item.contentType.slice(1).toLowerCase()
          vm.currentCom = comName
        }
      }
    }
  })
}

// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null
  }
  const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
  let date
  if (typeof time === 'object') {
    date = time
  } else {
    if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
      time = parseInt(time)
    } else if (typeof time === 'string') {
      time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '')
    }
    if ((typeof time === 'number') && (time.toString().length === 10)) {
      time = time * 1000
    }
    date = new Date(time)
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  }
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key]
    // Note: getDay() returns 0 on Sunday
    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value]
    }
    if (result.length > 0 && value < 10) {
      value = '0' + value
    }
    return value || 0
  })
  return time_str
}
