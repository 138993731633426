<template>
  <div class="">
    <div class="top-tags size14 top-tags-contian hidden-sm-and-down" v-if="tagsVies.length>0">
      <el-row type="flex" justify="center">
        <el-col :sm="24" :md="16">
          <span class="black-dec">当前位置：</span>
          <span v-for="(item,index) in tagsVies" :key="index" class="black-dec">
            <span :class="!item.path?'blue-main':''">{{ item.tags }}</span>
            <span class="than-tips" :class="tagsVies.length===index+1?'ele-hidden':''"> &gt; </span>
          </span>
        </el-col>
      </el-row>
    </div>
    <!-- 占位符-->
    <div class="hidden-md-and-up" style="padding-top: 55px"></div>
  </div>
</template>

<script>
import {mapState} from "vuex";

export default {
  name: "TagsView",
  data() {
    return {}
  },
  computed: {
    ...mapState('tags', ['tagsVies'])
  },
  methods: {}
}
</script>

<style scoped>
.top-tags-contian {
  padding-top: 125px;
}

.top-tags {
  height: 52px;
  line-height: 52px;
}
</style>
