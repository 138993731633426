import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
// import {mapMutations} from 'vuex'

Vue.use(VueRouter)

const routes = [{
  path: '/',
  name: 'Index',
  alias: '/',
  component: () => import('@/views/index/index'),
  meta: { title: '首页' }
}, {
  path: '/detail',
  name: 'InformationDetail',
  component: () => import('@/views/detail'),
  meta: { title: '资讯详情' }
}, {
  path: '/server',
  name: 'ReservationDownload',
  component: () => import('@/views/download/index'),
  meta: { title: '预约和下载' }
}]
//   , {
//     path: '/clinical',
//     name: 'Clinical',
//     component: () => import("@/views/clinical/index"),
//   meta: {title: '临床服务'},
// }, {
//     path: '/research',
//       name: 'Research',
//       component: () => import("@/views/medicalResearch/index"),
//       meta: {title: '医学研究'},
// }, {
//     path: '/news',
//       name: 'News',
//       component: () => import("@/views/news/index"),
//       meta: {title: '资讯中心'},
// }, {
//     path: '/about',
//       name: 'About',
//       component: () => import("@/views/about/index"),
//       meta: {title: '关于我们'},
// }, {
//     path: '/server',
//       name: 'ReservationDownload',
//       component: () => import("@/views/download/index"),
//       meta: {title: '预约和下载'},
// }

const router = new VueRouter({
  mode: 'history', // 去掉url中的#
  routes
})
// 路由前置守卫
router.beforeEach((to, from, next) => {
  if (to.fullPath == '/') {
    store.commit('tags/CHANGE_SHOW_TAGS', false)
  } else {
    store.commit('tags/CHANGE_SHOW_TAGS', true)
  }
  let tagsView = [{
    i: 1,
    tags: to.meta.title,
    path: to.fullPath
  }]
  store.commit('tags/ADD_TAGS_VIEW', tagsView)
  next()
})

// 切换路由，页面滚动到顶部
router.afterEach(() => {
  document.body.scrollTop = 0
  document.documentElement.scrollTop = 0
})

export default router
