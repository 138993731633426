// import Vue from 'vue'
import { getRouters } from '@/api/menu'

const state = {
  // 顶部导航菜单
  topbarRouters: [],
  // 菜单树
  menuTree: []
}

const mutations = {
  // 顶部导航菜单
  SET_TOPBAR_ROUTES: (state, routes) => {
    state.topbarRouters = routes
  },
  SET_TREE_ROUTES: (state, routes) => {
    state.menuTree = routes
  }
}

const actions = {
  // 生成路由
  GenerateRoutes({ commit }) {
    return new Promise(resolve => {
      // 向后端请求路由数据
      getRouters({ pageNum: 1, pageSize: 100000 }).then(res => {
        // 顶部菜单
        const topMenu = filterTopMenu(res.data)
        commit('SET_TOPBAR_ROUTES', topMenu)
        //菜单转为树形结构
        const menuTree = menuToTree(res.data, 0)
        commit('SET_TREE_ROUTES', menuTree)

        resolve(topMenu)
      })
    })
  }
}

// 筛选出顶部菜单
function filterTopMenu(data) {
  let topRouters = []
  data.forEach(item => {
    if (item.parentId === 0) {
      let router = {
        path: item.path,
        name: item.routeName,
        menuId:item.menuId,
        menuName:item.menuName,
        component: loadView(item.component),
        orderNum:item.orderNum,
        meta: { title: item.menuName,menuId: item.menuId }
      }
      topRouters.push(router)
    }
  })
  topRouters.sort(function(a, b){return a.orderNum-b.orderNum});
  return topRouters
}

// 将返回的路由转为菜单树
function menuToTree(params) {
  var result = []
  for (const param of params) {
    if (param.parentId === 0) {  // 判断是否为顶层节点
      let parent = {
        'menuId': param.menuId,
        'menuName': param.menuName
      }
      parent.children = getchilds(param.menuId, params)  // 获取子节点
      // result.push(parent)
      result[param.menuId]=parent
    }
  }
  return result
}

// 获取子节点
function getchilds(menuId, array) {
  const childs = []
  for (const arr of array) {  // 循环获取子节点
    if (arr.parentId == menuId) {
      childs.push({
        'menuId': arr.menuId,
        'menuName': arr.menuName,
        'parentId': arr.parentId,
        'isExpand': true,
        'contentType': arr.contentType,
        'routeName': arr.routeName,
        'menuType': arr.menuType,
        'path':arr.path
      })
    }
  }
  for (const child of childs) { // 获取子节点的子节点
    const childscopy = getchilds(child.menuId, array)// 递归获取子节点
    if (childscopy.length > 0) {
      child.children = childscopy
    }
  }
  return childs
}

// 加载路由
export const loadView = (view) => {
  return () => import(`@/views/${view}`)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
